import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

export const initBleedSwiper = () => {
  new Swiper('.carousel-bleed-sme', {
    modules: [Navigation, Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 15,

    breakpoints: {
      768: {
        slidesPerView: 2,
      },

      1024: {
        slidesPerView: 1.5,
      },
    },

    // Navigation arrows
    navigation: {
      nextEl: '.button-next',
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  });
};

export const initDefaultSwiper = () => {
  new Swiper('.default-slider', {
    modules: [Navigation, Pagination],
    slidesPerView: 1,

    // Pagination
    pagination: {
      el: '.default-swiper-pagination',
      clickable: true,
    },
  });
};
